import { __assign, __awaiter, __generator, __read } from "tslib";
import _ from 'lodash';
import createAsyncActions from '../../../../../shared/redux/helpers/create-async-actions';
import { normalizeArrayOfPaymentInfoVideos, normalizePaymentInfoChannel, normalizePaymentInfoGql, } from '../../../../../shared/redux/helpers/normalized';
import { getMemberInfo } from '../../../../../api/public/channel/channel';
import { getHydratedData } from '../../../client/hydrated-data/hydrated-data';
import { createAsyncProxy } from '../../../../../shared/worker/lib';
import { isGraphAPIEnabled } from '@wix/wix-vod-shared/dist/src/common/utils/experiments';
import { PublicChannelService } from '@wix/wix-vod-gql-api/dist/src/public/channel/channel';
import { PricingPlansAPI } from '@wix/wix-vod-pricing-plans-api';
import { dashify as turnIntoGUID } from '@wix/wix-vod-shared/dist/src/common/utils/guid';
import { getCurrentSiteUser } from '../../../../../shared/selectors/current-site-user';
import { isPricingPlanDealInfoType } from '../../../../selectors/channel-info';
export var CHANNEL_PAYMENT_ACTIONS = createAsyncActions('SERVER.CHANNEL.PAYMENT');
export var CHANNEL_PRICING_ACTIONS = createAsyncActions('SERVER.CHANNEL.PRICING');
var getPricingPlanPaymentInfo = function (dispatch, getState) {
    dispatch(CHANNEL_PRICING_ACTIONS.START());
    try {
        var state = getState();
        var instance = state.hydratedData.data.instance;
        var currentSiteUser = getCurrentSiteUser(state);
        var channelId = state.currentChannelId.currentChannelId;
        var pricingPlanService = new PricingPlansAPI(instance);
        return pricingPlanService
            .hasAccessToChannel(turnIntoGUID(channelId), currentSiteUser.id)
            .then(function (hasAccess) {
            dispatch(CHANNEL_PRICING_ACTIONS.SUCCESS({ hasAccess: hasAccess }));
        });
    }
    catch (_a) {
        dispatch(CHANNEL_PRICING_ACTIONS.FAIL());
    }
};
var getPaymentInfoGql = function (id) { return function (dispatch, getState, _a) {
    var createService = _a.createService;
    return __awaiter(void 0, void 0, void 0, function () {
        var state, videoIds, params, service, resp, response, reason_1, meta;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    state = getState();
                    videoIds = state.channelVideos.ids;
                    params = { id: id, videoIds: videoIds };
                    dispatch(CHANNEL_PAYMENT_ACTIONS.START(params));
                    service = createService(PublicChannelService);
                    _b.label = 1;
                case 1:
                    _b.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, service.getChannelVideosPaymentData(id, videoIds)];
                case 2:
                    resp = _b.sent();
                    response = {
                        data: {
                            result: {},
                            entities: normalizePaymentInfoGql(resp).entities,
                        },
                    };
                    dispatch(CHANNEL_PAYMENT_ACTIONS.SUCCESS(params, response));
                    return [3 /*break*/, 4];
                case 3:
                    reason_1 = _b.sent();
                    meta = {
                        analytics: { type: 'error', name: 'shared.channel.payment.get' },
                    };
                    if (_.isError(reason_1)) {
                        dispatch(CHANNEL_PAYMENT_ACTIONS.FAIL(reason_1, null, meta));
                    }
                    else {
                        dispatch(CHANNEL_PAYMENT_ACTIONS.FAIL(params, reason_1, meta));
                    }
                    return [2 /*return*/, Promise.reject(reason_1)];
                case 4: return [2 /*return*/];
            }
        });
    });
}; };
export var getPaymentInfo = createAsyncProxy('payment.getPaymentInfo', function (id, force) {
    if (force === void 0) { force = false; }
    return function (dispatch, getState) {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            var state, isPricingPlan, currentSiteUser, _c, instance, hydratedMemberInfo, items, options, params;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        state = getState();
                        isPricingPlan = isPricingPlanDealInfoType(((_b = (_a = state.hydratedData.data) === null || _a === void 0 ? void 0 : _a.channel) === null || _b === void 0 ? void 0 : _b.data.deal_info) ||
                            state.channelInfo.info.dealInfo);
                        if (isGraphAPIEnabled()) {
                            if (force) {
                                return [2 /*return*/, dispatch(getPaymentInfoGql(id))];
                            }
                            return [2 /*return*/, Promise.resolve()];
                        }
                        currentSiteUser = getCurrentSiteUser(state);
                        _c = getHydratedData(state), instance = _c.instance, hydratedMemberInfo = _c.memberInfo;
                        if (!(currentSiteUser && currentSiteUser.id)) {
                            return [2 /*return*/, Promise.resolve().then(function () {
                                    return dispatch(CHANNEL_PAYMENT_ACTIONS.SUCCESS());
                                })];
                        }
                        if (!isPricingPlan) return [3 /*break*/, 2];
                        return [4 /*yield*/, dispatch(getPricingPlanPaymentInfo)];
                    case 1:
                        _d.sent();
                        _d.label = 2;
                    case 2:
                        items = state.channelVideos.ids;
                        options = {
                            instance: instance,
                            items: items,
                        };
                        params = { id: id, options: options };
                        dispatch(CHANNEL_PAYMENT_ACTIONS.START(params));
                        return [2 /*return*/, getMemberInfo(id, instance, {
                                hydratedMemberInfo: hydratedMemberInfo,
                            }).then(function (response) {
                                var items = _.get(response, 'data.items', []);
                                if (!items.length) {
                                    dispatch(CHANNEL_PAYMENT_ACTIONS.SUCCESS(params, response));
                                    return response;
                                }
                                var _a = __read(_.partition(items, function (item) { return !item.itemId; }), 2), channelData = _a[0], videoDataList = _a[1];
                                var channel = _.get(channelData, '[0]');
                                var videos = _.map(videoDataList, function (video) {
                                    return _.pick(video, 'dgsInfo', 'itemId', 'listId');
                                });
                                var normalizeChannel = {};
                                if (channel) {
                                    normalizeChannel = __assign({}, normalizePaymentInfoChannel(_.pick(channel, 'dgsInfo', 'listId')));
                                }
                                /**
                                 * We are getting channel and video ids from backend in form 75597969-d0e0fda03fb64c7bbed8746046bff110,
                                 * where 75597969 - publish id, d0e0fda03fb64c7bbed8746046bff110 - item id.
                                 * This id is stored in itemId property.
                                 * But when we receive dgsInfo from member info - we have simple id in above mentioned
                                 * property.
                                 * So we extracting only dgsInfo, dropping itemId.
                                 */
                                var _b = normalizeArrayOfPaymentInfoVideos(videos), result = _b.result, entities = _b.entities;
                                var dgsInfo = _.reduce(entities.videos, function (acc, _a, key) {
                                    var _b;
                                    var dgsInfo = _a.dgsInfo;
                                    return (__assign(__assign({}, acc), (_b = {}, _b[key] = { dgsInfo: dgsInfo }, _b)));
                                }, {});
                                response = __assign(__assign({}, response), { data: _.merge({}, normalizeChannel, {
                                        result: result,
                                        entities: __assign(__assign({}, entities), { videos: dgsInfo }),
                                    }) });
                                dispatch(CHANNEL_PAYMENT_ACTIONS.SUCCESS(params, response));
                                return response;
                            }, function (reason) {
                                var meta = {
                                    analytics: { type: 'error', name: 'shared.channel.payment.get' },
                                };
                                if (_.isError(reason)) {
                                    dispatch(CHANNEL_PAYMENT_ACTIONS.FAIL(reason, null, meta));
                                }
                                else {
                                    dispatch(CHANNEL_PAYMENT_ACTIONS.FAIL(params, reason, meta));
                                }
                                return Promise.reject(reason);
                            })];
                }
            });
        });
    };
});
