import _ from 'lodash';
import { createSelector } from 'reselect';
import { DEAL_INFO_TYPES } from '@wix/wix-vod-constants/dist/common/deal-info-types';
export var getChannelId = function (state) { return _.get(state, 'channelInfo.info.id'); };
export var getCurrentChannelId = function (state) {
    return state.currentChannelId.currentChannelId;
};
var getChannelInfo = function (state) { return state.channelInfo.info; };
export var isPricingPlanDealInfoType = function (dealInfo) {
    return _.some(dealInfo, function (_a) {
        var type = _a.type;
        return type === DEAL_INFO_TYPES.PLAN;
    });
};
export var isPricingPlan = createSelector(getChannelInfo, function (channelInfo) {
    return isPricingPlanDealInfoType(channelInfo.dealInfo);
});
