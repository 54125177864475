var _a, _b, _c;
export var PAYMENT_TYPE = {
    SUBSCRIPTION: 'subscription',
    RENT: 'rent',
    PURCHASE: 'sale',
    MEMBER_ONLY: 'memberOnly',
    PLAN: 'plan',
};
export var PAYMENT_PERMISSIONS = {
    UNLIMITED_STREAMING: 'unlimitedStreaming',
    INSTANT_ACCESS: 'instantAccess',
    MONTHLY_PAYMENT: 'monthlyPayment',
    SINGLE_VIDEO: 'singleVideo',
    ONE_PAYMENT: 'onePayment',
    SINGLE_VIDEO_STREAMING: 'singleVideoStreaming',
    STREAMING_PERIOD: 'streamingPeriod',
    UNLIMITED_DOWNLOADS: 'unlimitedDownloads',
    DOWNLOAD_ANY_TIME: 'downloadAnytime',
};
var UNLIMITED_STREAMING = PAYMENT_PERMISSIONS.UNLIMITED_STREAMING, INSTANT_ACCESS = PAYMENT_PERMISSIONS.INSTANT_ACCESS, MONTHLY_PAYMENT = PAYMENT_PERMISSIONS.MONTHLY_PAYMENT, SINGLE_VIDEO = PAYMENT_PERMISSIONS.SINGLE_VIDEO, ONE_PAYMENT = PAYMENT_PERMISSIONS.ONE_PAYMENT, SINGLE_VIDEO_STREAMING = PAYMENT_PERMISSIONS.SINGLE_VIDEO_STREAMING, STREAMING_PERIOD = PAYMENT_PERMISSIONS.STREAMING_PERIOD, UNLIMITED_DOWNLOADS = PAYMENT_PERMISSIONS.UNLIMITED_DOWNLOADS, DOWNLOAD_ANY_TIME = PAYMENT_PERMISSIONS.DOWNLOAD_ANY_TIME;
var PAYMENT_PERMS = (_a = {},
    _a[PAYMENT_TYPE.SUBSCRIPTION] = [
        UNLIMITED_STREAMING,
        INSTANT_ACCESS,
        MONTHLY_PAYMENT,
    ],
    _a[PAYMENT_TYPE.PURCHASE] = [SINGLE_VIDEO, ONE_PAYMENT, UNLIMITED_STREAMING],
    _a[PAYMENT_TYPE.RENT] = [SINGLE_VIDEO_STREAMING, STREAMING_PERIOD],
    _a[PAYMENT_TYPE.MEMBER_ONLY] = [SINGLE_VIDEO, UNLIMITED_STREAMING],
    _a[PAYMENT_TYPE.PLAN] = [UNLIMITED_STREAMING, INSTANT_ACCESS],
    _a);
var DOWNLOAD_PERMS = (_b = {},
    _b[PAYMENT_TYPE.SUBSCRIPTION] = [UNLIMITED_DOWNLOADS],
    _b[PAYMENT_TYPE.PURCHASE] = [DOWNLOAD_ANY_TIME],
    _b[PAYMENT_TYPE.RENT] = [],
    _b[PAYMENT_TYPE.MEMBER_ONLY] = [DOWNLOAD_ANY_TIME],
    _b);
export function getPaymentPermissions(_a) {
    var paymentType = _a.paymentType, downloadEnabled = _a.downloadEnabled;
    var permissions = PAYMENT_PERMS[paymentType];
    return downloadEnabled
        ? permissions.concat(DOWNLOAD_PERMS[paymentType])
        : permissions;
}
export var CHARGE_INTENT = {
    SALE: 'SALE',
    RECURRING: 'RECURRING',
};
export var CHARGE_INTENT_BY_TYPE = (_c = {},
    _c[PAYMENT_TYPE.SUBSCRIPTION] = CHARGE_INTENT.RECURRING,
    _c[PAYMENT_TYPE.PURCHASE] = CHARGE_INTENT.SALE,
    _c[PAYMENT_TYPE.RENT] = CHARGE_INTENT.SALE,
    _c[PAYMENT_TYPE.MEMBER_ONLY] = null,
    _c);
export var VIDEO_PAYMENT_LOGOUT_KEY = 'videoPaymentLogOut';
